import { merge } from "theme-ui";
import tailwind from "@theme-ui/preset-tailwind"
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

/* note - this is a .ts file in the source repo (https://github.com/LekoArts/gatsby-themes/blob/main/themes/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index.ts). not sure why, but trying to change this to .ts will make it miss the file entirely. */

const theme = merge(originalTheme, {
  initialColorModeName: `light`,
  colors: {
    background: '#eee',
    primary: tailwind.colors.blue[7],
    modes: {
      dark: {
        background: '#333',
        primary: tailwind.colors.blue[4],
        secondary: tailwind.colors.gray[5],
        divide: tailwind.colors.gray[6],
        muted: tailwind.colors.gray[6],
      }
    },
  },
  fonts: {
    body: `Inter, -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  styles: {
    p: {
      fontSize: [1],
    },
    ul: {
      li: {
        fontSize: [1],
      }
    },
    ol: {
      li: {
        fontSize: [1],
      },
    },
    h1: {
      fontSize: [6],
    },
    h2: {
      fontSize: [5],
    },
    h3: {
      fontSize: [4],
    },
    h4: {
      fontSize: [3],
    },
    h5: {
      fontSize: [2],
    },
    h6: {
      fontSize: 1,
    },
  },
  copyButton: {
    fontSize: [`14px`],
  },
  links: {
    listItem: {
      fontSize: [1, 2],
    },
  },
});

export default theme;
